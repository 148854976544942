<template>
    <div class="container-fluid" style="height: 100%;">
      <div class="row">
        <main class="col-md-12" style="background-color: #F7F7F8;">
          <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
            <div class="col-12 text-center">
              <div class="filters mb-4">
                <div class="row g-2 align-items-center">
                  <!-- Date Filters -->
                  <div class="col-2">
                    <label for="startDate" class="form-label fw-bold">Start Date</label>
                    <input
                      type="date"
                      v-model="filters.startDate"
                      class="form-control"
                      id="startDate"
                    />
                  </div>
                  <div class="col-2">
                    <label for="endDate" class="form-label fw-bold">End Date</label>
                    <input
                      type="date"
                      v-model="filters.endDate"
                      class="form-control"
                      id="endDate"
                    />
                  </div>
                  <div class="col-1">
                  <button
                    :disabled="!isGenerateEnabled"
                    class="btn btn-more w-100 mt-4"
                    @click="generateReport"
                  >
                  Generate Report
                  </button>
                </div>
                </div>
              </div>
  
              <!-- Reports Table -->
              <div class="table-responsive-lg">
                <table class="table table-hover table-striped table-bordered">
                  <thead class="table-dark">
                    <tr>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;"
                      >
                        ID
                      </th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;"
                      >
                      Start Date
                      </th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;"
                      >
                      End Date
                      </th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;"
                      >
                      Généré à
                      </th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;"
                      >
                      Télécharger
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="report in reports" :key="report.id">
                      <td style="vertical-align: middle; text-align: center;">{{ report.id }}</td>
                      <td style="vertical-align: middle; text-align: center;">{{ formatDate(report.start_date) }}</td>
                      <td style="vertical-align: middle; text-align: center;">{{ formatDate(report.end_date) }}</td>
                      <td style="vertical-align: middle; text-align: center;">{{ formatDateTime(report.created_at) }}</td>
                      <td
                        style="vertical-align: middle; text-align: center; color: #E5097F; cursor: pointer; text-decoration: underline;"
                      >
                      <a :href="getDownloadUrl(report.id)" target="_blank" class="btn btn-request">Télécharger</a>
                      </td>
                    </tr>
                    <tr v-if="!reports.length">
                      <td colspan="5" class="text-center">No reports available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "Dashboard",
    data() {
      return {
        filters: {
          startDate: "",
          endDate: "",
        },
        reports: [],
      };
    },
    computed: {
      isGenerateEnabled() {
        return this.filters.startDate && this.filters.endDate;
      },
    },
    methods: {
  async fetchReports() {
    try {
      const response = await axios.get("/api/v1/reports/");
      this.reports = response.data;
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  },
  async generateReport() {
    try {
      const response = await axios.get("/api/v1/export-shipments/", {
        params: {
          start_date: this.filters.startDate,
          end_date: this.filters.endDate,
        },
      });
      const { message } = response.data;

      alert(message);
      await this.fetchReports();
    } catch (error) {
      console.error("Error generating report:", error);
      alert("Failed to generate report. Please try again.");
    }
  },
  formatDate(date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date).toLocaleDateString(undefined, options);
  },
  formatDateTime(datetime) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(datetime).toLocaleString(undefined, options);
  },
  getDownloadUrl(report_id) {
    return `https://demo.api.eurekali-point.com/api/v1/admin/reports/print/${report_id}/`;
  },
},

    async mounted() {
      await this.fetchReports();
    },
  };
  </script>
  
  <style scoped>
  .btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding: 8px 15px;
  }
  
  .btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
  }
  
  .btn-request {
    background-color: #BCF2C0;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding: 5px 10px;
    font-weight: bold;
  }
  
  .btn-request:hover {
    background-color: #37B980;
    color: white;
  }
  </style>
  
